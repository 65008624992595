import { React, styled, firebase } from "../shipityo/index";
import Layout from "../layouts/index";

import { Helmet } from "react-helmet";
import Img from "gatsby-image";

const GoogleMapContainer = styled.div`
    width:100%;
    margin-top: 30px;
`;

const SucessBox = styled.div`
  text-align: center;
  .hidden{
    display: none;
  }
  .show {
    padding-top: 20px;
    text-transform: uppercase;
    font-size: 1.2em;
    background: #FCE20A; 
    color: #000;
    border-radius: 15px;
  }
  
`;

const Container = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
    color: #6a6a6a;
    @media only screen 
    and (max-width: 650px)  {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    } 
`;

const ContactUsPageInner = styled.div`
    display: flex;
    flex-wrap: nowrap;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-wrap: wrap;
    } 
    flex-direction: row;
    justify-content: center; 
    padding: 10px;
    color: #fff;
    text-align: right;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 400px) {
        padding: 2px;
    }
`;

const ContactInfo = styled.div`
    color: #848484;
    width: 550px;   
    text-align: center;
    font-size: 2em;
    flex-basis: 50%;
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-basis: 100%;
    } 
    div {
        padding: 30px;
        heading-line {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 400px) {
        padding: 2px;
    }
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 500px) {
        
    } 
`;

const ContactForm = styled.div`
    color: #848484;
    margin-top: 50px;
    width: 100%; 
    flex-basis: 50%;
    @media only screen
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-basis: 100%;
    } 
    text-align: left;
    div {
        padding: 5px;
    }
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 400px) {
        padding: 5px;
    }
    #submitbutton{
        background-color: #CCC;
        color: #000;
        padding: 7px;
        display: inline-block;
    }
    form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 2fr 2fr 1fr ;
        @media only screen 
        and (min-width: 1px) 
        and (max-width: 500px) {
            grid-template-rows: 2fr 2fr 2fr 2fr 1fr ;
        }
        .text-input-field {
            background: #fff;
            border: none;
            border-radius: 10px;
            width: 100%;
            height: 30px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 4px;
            padding-right: 4px;
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .30);
            :hover{
                box-shadow: 5px 10px 10px rgba(0, 0, 0, .30) inset;
                background: #FCE20A;
            }
        }
        #name {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 500px) {
                grid-row: 1 / span 1;
                grid-column: 1 / span 2;
            }
        }
        #email {
            grid-row: 1 / span 1;
            grid-column: 2 / span 1;
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 500px) {
                grid-row: 2 / span 1;
                grid-column: 1 / span 2;
            }
        }
        #phone{
            grid-row: 2 / span 1;
            grid-column: 1 / span 2;
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 500px) {
                grid-row: 3 / span 1;
                grid-column: 1 / span 2;
            }
        }
        #message{
            grid-row: 3 / span 1;
            grid-column: 1 / span 2;
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 500px) {
                grid-row: 4 / span 1;
                grid-column: 1 / span 2;
            }
        }
        #submitbutton{
            grid-column-start: 2;
            grid-column-end: 2;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #515151;
            border-radius: 10px;
            text-transform: uppercase;
            color: #fff;
            margin-top: 10px;
            padding: 10px;
            box-shadow: 5px 10px 10px rgba(0, 0, 0, .30);
            :hover{
                box-shadow: 5px 10px 10px rgba(0, 0, 0, .30) inset;
                background: #FCE20A;
                color: #000;
                cursor: pointer;
            }
            @media only screen 
            and (min-width: 1px) 
            and (max-width: 500px) {
                grid-row: 5 / span 1;
                grid-column: 1 / span 2;
            }

        }
    }
`;

class ContactUs extends React.Component {

    constructor({data}) {
        let  pageImages =[];
        super();
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            submitReadyName: "",
            submitReadyEmail: "",
            submitReadyPhone: "",
            submitReadyMessage: "",
            notification: "Your Message was sent",
        };
        this.render = this.render.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        if(data){
            if(data.allFile){
                if(data.allFile.edges){
                    pageImages = data.allFile.edges
                    this.state = {pageImages: pageImages};
                } 
            } 
        }
    }

    countUp = () => {
        this.setState({
            count : this.state.count + 1
        }) 
    }

    countDown = () => {
        this.setState({
            count : this.state.count - 1
        }) 
    }

    removeSucessMessage  = () => {
        setTimeout(function(){ 
            document.getElementById("succes").classList.add("hidden");
            document.getElementById("succes").classList.remove("show");
        }, 3000);
    }

    handleSubmit = () => {

        if (typeof window !== 'undefined') {

            //let self = this;
            let notification = "Your Message was sent";
    
            let obj = { 
                name: this.state.name,
                email: this.state.email, 
                phone: this.state.phone,
                message: this.state.message,
                timestamp: Date.now(), 
                timestamphuman: new Date().toLocaleString()
            };
            //let myJSON = JSON.stringify(obj);
            let docRef = obj.timestamp.toString()
    
            const db = firebase.db
    
            db.collection('projects/loggenbergattorneys/messages/').doc(docRef).set(obj);  
    
            
            this.setState({ 
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
            });
            document.getElementById("succes").classList.remove("hidden");
            document.getElementById("succes").innerHTML = notification;
            document.getElementById("succes").classList.add("show");
            this.removeSucessMessage();
    
            return undefined

        }

        return undefined
    }

    handleName = (e) => {
        
        this.setState({
            name: e.target.value,
            submitReadyName: true
        });

    }

    handleEmail = (e) => {
        this.setState({
            email: e.target.value,
            submitReadyEmail: true
        });

    }

    handlePhone = (e) => {
        this.setState({
            phone: e.target.value,
            submitReadyPhone: true
        });

    }

    handleMessage = (e) => {
        this.setState({
            message: e.target.value,
            submitReadyMessage: true
        });

    }

    render() {

        //let notification = this.state.name;

        return (
            <Layout>
            <div>
                <div id="home-images">
                    {this.state.pageImages.map((image, index) => (
                        <div key={index}>
                            <Img sizes={image.node.childImageSharp.sizes} />
                        </div>
                    ))}
                </div>
                <Container>
                    <Helmet>
                        <title>Loggenberg Attorneys Incorporated | Contact</title>
                        <meta name="description" content="Loggenberg Attorneys Incorporated | Contact" />
                    </Helmet>
                    <ContactUsPageInner>
                        <ContactInfo>
                            <div>
                                <h3>AT YOUR SERVICE</h3>
                                <p>
                                    <span className="heading-main">MOBILE: 082 358 8065</span><br/>
                                    <span className="heading-main">OFFICE: 021 712 3921</span><br/>
                                </p>
                                <hr className="heading-line"/>
                                <h3>OFFICE HOURS</h3>
                                <p>
                                    <span className="heading-main">MONDAY - FRIDAY</span><br/>
                                    <span className="heading-main">09:00 - 16:30</span>
                                </p>
                            </div>
                        </ContactInfo>
                        <ContactForm>
                            <div>
                                <form>
                                    <div id="name">
                                        <label>Name</label>
                                        <input className="text-input-field" value={this.state.name} onChange={this.handleName} type="text" name="value1"/>
                                    </div>
                                    <div id="email">
                                        <label>Email</label>
                                        <input className="text-input-field" value={this.state.email} onChange={this.handleEmail} type="text" name="value2"/>
                                    </div>
                                    <div id="phone">
                                        <label>Phone: </label>
                                        <input className="text-input-field" value={this.state.phone} onChange={this.handlePhone} type="text" name="value3"/>
                                    </div>
                                    <div id="message">
                                        <label>Message</label>
                                        <input className="text-input-field" value={this.state.message} onChange={this.handleMessage} type="text" name="value4"/>
                                    </div>
                                    <div id="submitbutton" onClick={this.handleSubmit}>Send Message</div>
                                </form>
                                <SucessBox>
                                    <div id="succes" className="hidden">
                                    </div>
                                </SucessBox>
                            </div>
                        </ContactForm>
                    </ContactUsPageInner>
                    <GoogleMapContainer>
                        <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.28623666408!2d18.468666314852207!3d-34.06217598060324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc418119d7c94d%3A0x99e627406ae2cb53!2s33+Joe+Marks+Blvd%2C+Retreat%2C+Cape+Town%2C+7965!5e0!3m2!1saf!2sza!4v1553669860057" width="100%" height="300px" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                    </GoogleMapContainer>
                </Container>
            </div>
            </Layout>
            
        )
    }
}
  
export default ContactUs;

export const query = graphql`
    query ContactQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/pagebanners/contact/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1900) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
            }
          }
        }
      }
    }
  `