import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
//import PracticeAreas from "../helpers/practice-areas.json"

const Footer = styled.div`
    width: 100%;
    color: #ffffff;
    background: #969696;
`;

const FooterColumns = styled.div`
    padding: 50px;  
    display: flex;
    justify-content: center;
    h3{
        border-bottom: 1px solid #ffffff;
        padding-bottom: 7px;
    }
    .footer-column{
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;  
        padding-right: 30px; 
        flex-basis: 100%;
    }
    #contact{
        div{
            flex-basis: 100%;
        }
        #contact-inner{
            display: flex;
            flex-direction: column;
            @media only screen 
            and (min-width: 650px)  {
                flex-direction: row;
                .contact-break{
                    display:none
                }
            } 
        }
    }
    #areas{
        a {
            color: #FFF;
        }

    }
    @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        flex-direction: column;
    } 
`;

const FooterBottom = styled.div`
    color: #ffffff;
    background: #4b4b4b;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 80px;
    padding-right: 80px;
`;

class Component extends React.Component {

    constructor() {
      super();
      this.state = {
      };
      this.render = this.render.bind(this);
    }


    componentDidMount(){
        // let path = this.props.layoutData.location.pathname;
    }
  
    render() {
        return (
            <Footer>
                <FooterColumns>
                    <div id="contact" className="footer-column">
                        <h3>CONTACT US</h3>
                        <div id="contact-inner">
                            <div> 
                                <p>
                                ADDRESS:<br/>
                                33 Joe Marks Boulevard<br/>
                                Retreat, Cape Town<br/>
                                7945
                                </p>
                            </div>
                            <div>
                                <p>
                                    MOBILE: <br className="contact-break"/>+27 (0)82 358 8065<br/>
                                    OFFICE: <br className="contact-break"/>+27 (0)21 712 3921<br/>
                                    FAX: <br className="contact-break"/>+27 (0)86 211 6926
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="areas" className="footer-column">
                        <h3 >PRACTICE AREAS</h3>
                        <p>
                            <Link to="/attorneys/family-law">Family Law </Link> | <Link to="/attorneys/labour-law">Labour Law</Link><br/>
                            <Link to="/attorneys/personal-injury-law">Personal Injury Law </Link> | <Link to="/attorneys/human-rights-law"> Human Rights Law </Link><br/>
                            <Link to="/attorneys/civil-litigation-law">General Civil Litigation </Link><br/>
                        </p>
                    </div>
                </FooterColumns>
                
                <FooterBottom>
                <small>Copyright &copy; 2019 LOGGENBERG ATTORNEYS INCORPORATED. </small>
                </FooterBottom>
            </Footer>
        ) 
    }
  }

  
  export default Component;
  
  export const query = graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `
  /*
    warning The GraphQL query in the non-page component 
    "/Users/bh/Documents/GitHub/loggenbergattorneys-coza/src/components/footer.js" 
    will not be run.
    Queries are only executed for Page or Layout components. Instead of a query,
    co-locate a GraphQL fragment and compose that fragment into the query (or other
    fragment) of the top-level page or layout that renders this component. For more
    info on fragments and composition see: http://graphql.org/learn/queries/#fragments
  */