import React from "react";
import Link from "gatsby-link";
//import graphql from "gatsby";
import IconBars from "../icons/bars.svg";
import IconFacebook from "../icons/facebook.svg";
import IconTwitter from "../icons/twitter.svg";
import IconPhone from "../icons/phone.svg";
import IconTimes from "../icons/times.svg";
import styled from "styled-components";
import HeaderLogo from "../img/logo-header.png"
// import NavActive from "../img/nav-active.png"


import SVG from 'react-inlinesvg';



const Header = styled.div`
    width: 100%;
    margin: 0px;
    padding: 0px;
    z-index: 100;
    .HeaderInner {
        z-index: 100;
        transition: all 3s;
        background: rgba(255,255,255, 0.95);
        padding-top: 10px;
        position: fixed;
        top: 0;
        left:0;
        display: block;
        width: 100%;
        .headerimage {
            height: 70px;
            line-height: 70px;
            margin-bottom: 0px;
            float: left;
        };
    }
    .scrolled{
        background: #000 !important;
    }
`;

const Nav = styled.div`

    .active{
        border-bottom: solid #CCC;
    }

    position: absolute;
    z-index: 101;
    height: 100px;
    left: 50%;
    margin-left: -25%;
    a {
        color: #929292;
        z-index: 105;
    }
    a:hover{
        color: #4a494b;
        font-weight: 600;
    }
    .navitems {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
            a {
                height: 70px;
                line-height: 70px;
                padding-left: 20px;
                padding-right: 20px;
            };
        };
    }
 
  @media only screen 
    and (min-width: 901px) 
    and (max-width: 1100px) {
        left: 40%;
  }
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        display: none !important;
  } 
`;

const NavToggle = styled.div`
    float: right;
    div{
        svg{
            height: 50px; 
            line-height: 50px;
            margin: 10px; 
        }
    }   
    
    @media only screen 
    and (min-width: 901px)  {
        display: none !important;
    }
    @media only screen 
        and (min-width: 1px) 
        and (max-width: 900px) {
        display: inline-block !important;
    } 
`;

const NavToggleContent = styled.div`
    background: #000;
    min-height: 10px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 87px;
    display: block;
    ul{
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: space-around;
        margin: 0px;
        padding: 0px;
    }
    li{
        div{
            text-align: center;
            background: #848484;
            list-style: none;
            font-size: 1.5em;
        }
    }
    a {
        color: #FFF;
    }
`;


const Social = styled.div`

    margin-top: 18px;
    a {
        color: #a4a4a5;
    }
    position: absolute;
    right: 2%;
    .social-icon{
        display: inline-block;
        border: 2px solid #a4a4a5;
        border-radius: 50px;
        padding: 5px;
    }
    .social-icon-inner{
        height: 20px;
        width: 20px;
        text-align: center;
        margin-left: 3px;
        margin-right: 3px;
        svg{
            object-fit: cover;
            overflow: hidden;
            height: 100%;
        }
    }
    .social-icon:hover{
        border: 2px solid #4a494b;
        color: white;
        background:#4a494b;
    }
 
  @media only screen 
    and (min-width: 901px)  {
        
  }
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
        display: none !important;
  } 
 
  @media only screen 
    and (max-width: 1px) 
    and (max-width: 900px) {
  }
  @media only screen 
    and (min-width: 901px)  {

  }
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
    
  } 
`;


class MobileNav extends React.Component {

    constructor({data}) {
        super();
        this.state = {
            toggle: false
        };
        this.render = this.render.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav() {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        if (this.state) {
            if (this.state.toggle) {
                return (
                    <div>
                        <NavToggle>
                            <div onClick={this.toggleNav}>
                                <SVG className="navtogglesvg" src={IconTimes}></SVG>
                            </div>
                        </NavToggle>
                        <NavToggleContent>
                            <ul className="navitems">
                                <li><Link to="/"> <div>Home</div>  </Link></li>
                                <li><Link to="/about" activeClassName="active" ><div>About</div> </Link></li>
                                <li><Link to="/services" activeClassName="active"><div>Services</div></Link></li>
                                <li><Link to="/articles" activeClassName="active"><div>Articles</div></Link></li>
                                <li><Link to="/contact" activeClassName="active"><div>Contact</div></Link></li>
                            </ul>
                        </NavToggleContent>
                    </div>    
               )
            }
            else{
                return(
                    <NavToggle>
                        <div onClick={this.toggleNav}>
                            <SVG className="navtogglesvg"src={IconBars}></SVG>
                        </div>
                   </NavToggle>
               )
            }
            
        }
    
    }
}

class Component extends React.Component {

    constructor() {
      super();
      this.state = {
          toggle: false
      };
      this.render = this.render.bind(this);
    }

    toggleNav(e){

        this.setState({
            toggle: !this.state.toggle
        })
  
        if(e.target) {
            console.log(e.target);
            console.log("this.state.toggle");
            console.log(this.state.toggle);
            if(e.target) {
            if(e.target.id) {
                console.log(e.target.id);
                if(e.target.id === "menuicon"){
                document.getElementById("menuicon").classList.add("nav-hide");
                document.getElementById("cross").classList.remove("nav-hide");
                document.getElementById("nav-contact").classList.add("nav-contact-mobile");
                document.getElementById("nav-main").classList.add("nav-contact-mobile");
                return null
                }
                else if(e.target.id  === "cross"){
                document.getElementById("cross").classList.add("nav-hide");
                document.getElementById("menuicon").classList.remove("nav-hide");
                document.getElementById("nav-contact").classList.remove("nav-contact-mobile");
                document.getElementById("nav-main").classList.remove("nav-contact-mobile");
                return null
                }
                else{
                alert("Error");
                }
            }
            }
        }
  
    }

    componentDidMount(){
        /*
        let path = this.props.layoutData.location.pathname;
        console.log("path");
        console.log(path);
        if(path === "/"){
        }
        */
    }
  
    render() {

        return (
            <Header>
                <div id="page-header" className="HeaderInner">
              
                    <Link to="/"><img className="headerimage" src={HeaderLogo} alt="Loggenberg Attorneys Logo Small"></img></Link>
                    <Nav>
                        <ul className="navitems">
                            <li><Link to="/" >Home  </Link></li>
                            <li><Link to="/about" activeClassName="active" >About </Link></li>
                            <li><Link to="/services" activeClassName="active">Services</Link></li>
                            <li><Link to="/articles" activeClassName="active">Articles</Link></li>
                            <li><Link to="/contact" activeClassName="active">Contact</Link></li>
                        </ul>
                    </Nav>
                    <NavToggle>
                        <MobileNav toggle={this.state.toggle} handler={this.toggleNav.bind(this)}/>
                    </NavToggle>
                    
                    <Social>
                        <Link id="phone" to="/contact" >
                            <div className="social-icon">
                                <div className="social-icon-inner">
                                    <SVG src={IconPhone}></SVG>
                                </div>
                            </div>
                        </Link>
                        <Link id="facebook" to="/contact">
                            <div className="social-icon">
                                <div className="social-icon-inner">
                                    <SVG src={IconFacebook}></SVG>
                                </div>
                            </div>
                        </Link>
                        <Link id="twitter" to="/contact">
                            <div className="social-icon">
                                <div className="social-icon-inner">
                                    <SVG src={IconTwitter}></SVG>
                                </div>
                            </div>
                        </Link>
                    </Social>
                </div>
            </Header>
        ) 
    }
  }

    /*
        <div id="menuicon" ref="menuicon" className="menuicon no-class" onClick={this.toggleNav.bind(this)}>
        </div>
        <div id="cross" ref="cross" className="cross nav-hide" onClick={this.toggleNav.bind(this)}>
        </div>
    */
  
  export default Component;
  
  export const query = graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `