import { React, Normalize, styled } from '../shipityo'
import { StaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet";

import Header from "../components/header"
import Footer from "../components/footer"


const StyleWrapper = styled.div`
  font-family: Helvetica, Arial, Sans-Serif;
  a {
      text-decoration: none;
  }
  .header {
    background: #000 !important;
  }
`;

const Content = styled.div`
  z-index: -100;
  margin-top: 90px;
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
  } 
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
  } 
  @media only screen 
    and (min-width: 1px) 
    and (max-width: 900px) {
  } 
`;


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutNoBannerQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(filter: {relativeDirectory: {regex: "/img/banners/"}}) {
        edges {
          node {
            name
            relativePath
            prettySize
            extension
            birthTime
            childImageSharp {
              sizes(maxWidth: 1400) {
                  ...GatsbyImageSharpSizes_tracedSVG
                }
              }
            }
          }
        }
      } 
    `}
    render={data => (
      <Normalize>
        <StyleWrapper className="main-wrapper">
          <Helmet>
              <title>{data.site.siteMetadata.title}</title>
              <meta name="description" content="Loggenberg Attorneys Incorporated" />
              <meta name="keywords" content="Loggenberg Attorneys Incorporated" />
              <html lang="en" />
              <meta name="og:type" content="website" />
              <meta name="og:site_name" content="Loggenberg Attorneys Incorporated" />
              <link
                rel="canonical"
                href={`http://loggenbergattorneys.co.za`}
              />
            </Helmet>
            <Header siteTitle={data.site.siteMetadata.title} />
            <Content>
              {children}
            </Content>
            <Footer />
        </StyleWrapper>
      </Normalize>
    )}
  />
)

export default Layout;


/*

class Component extends React.Component {

  constructor({ data }) {
    let stateData = [];
    if(data){
        if(data.allFile){
            if(data.allFile.edges){
                stateData = data.allFile.edges
            } 
        } 
    }
    super();
    this.state = {
      bannerImages: stateData,
      bannerState: 1
    };    
  }
  componentDidMount(){
  }

  render() {
    let self = this;

    let bannerState = this.state.bannerState;


    let val = this.props.valueOf().location.pathname.toString();
    console.log(val);
  
    return (
        <MainWrapper>
          <Helmet>
            <title>{this.props.valueOf().data.site.siteMetadata.title}</title>
            <meta name="description" content="Loggenberg Attorneys Incorporated" />
            <meta name="keywords" content="Loggenberg Attorneys Incorporated" />
            <html lang="en" />
            <meta name="og:type" content="website" />
            <meta name="og:site_name" content="Loggenberg Attorneys Incorporated" />
            <link
              rel="canonical"
              href={`http://loggenbergattorneys.co.za${this.props.location.pathname}`}
            />
          </Helmet>
          <Header className="header" layoutData={this.props} />
          <Content>
            {this.props.children()}
          </Content>
          <Footer layoutData={this.props} />
        </MainWrapper>
    ) 
  }
}

export default Component;

export const query = graphql`
  query LayoutNoBannerQuery {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {relativeDirectory: {regex: "/img/banners/"}}) {
      edges {
        node {
          name
          relativePath
          prettySize
          extension
          birthTime
          childImageSharp {
            sizes(maxWidth: 1400) {
                ...GatsbyImageSharpSizes_tracedSVG
              }
          }
        }
      }
    }
  }
`

*/











/*


import Header from "./header"

const StyleWrapper = styled.div`
  margin: 0px auto;
  max-width: 960px;
  padding-top: 0px;
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Normalize>
        <StyleWrapper className="main-wrapper">
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            <main>{children}</main>
            <footer>
              © {new Date().getFullYear()}
            </footer>
          </div>
        </StyleWrapper>
      </Normalize>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

*/